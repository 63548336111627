import React from 'react';
import Masonry from 'react-masonry-css';
import './Gallery.css';

const Gallery = ({ mediaItems }) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="gallery-masonry"
      columnClassName="gallery-masonry-column"
    >
      {mediaItems.map((item) => (
        <div key={item.id} className="gallery-item">
          {item.content_type === "photo" ? (
            <img src={item.photo} alt={`Item ${item.id}`} loading="lazy" />
          ) : (
            <div className="video-container">
              <iframe
                src={item.youtube_link.replace("watch?v=", "embed/")}
                title={`Video ${item.id}`}
                frameBorder="0"
                allowFullScreen
                loading="lazy"
              />
            </div>
          )}
        </div>
      ))}
    </Masonry>
  );
};

export default Gallery;

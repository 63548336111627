import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./BlogDetail.css"; // Import custom CSS for styling
import dateFormat from 'dateformat';
import BoltLoader from './loaders/boltloader';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Fetch the specific blog by ID from the Django API
    fetch(`${process.env.REACT_APP_API_URL}/api/articles/${id}/`) // Update with your actual API URL
      .then((response) => response.json())
      .then((data) => setBlog(data))
      .catch((error) => console.error("Error fetching blog:", error));
  }, [id]);

  if (!blog) {
    return <>
    <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <BoltLoader
        className={"loaderbolt"}
        boltColor={"#fa6616"}
        backgroundBlurColor={"#E0E7FF"}
      />
      </div>
  </>;
  }

  return (
    <div className="blog-detail-container">
      <div className="blog-detail-header">
        <h1 className="blog-title">{blog.title}</h1>
        <p className="blog-author">By {blog.author}</p>
        <img src={blog.poster || "https://via.placeholder.com/400x200"} alt={blog.title} className="blog-image" />
      </div>
      <div className="blog-meta">
        <span>
          <i className="ti-comment" /> {blog.specialist}
        </span>
        <span>
          <i className="ti-thumb-up" /> {dateFormat(blog.datetime, "mmmm dS, yyyy")}
        </span>
      </div>
      <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} >
      </div>
    </div>
  );
};

export default BlogDetail;

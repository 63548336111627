import React from "react";
import { Link } from "react-router-dom";

class Health extends React.Component {
  render() {
    return (
      <div className="health_section layout_padding" id="aboutus">
        <div className="container">
          <h1 className="health_taital">Expert Orthopedic Care for You</h1>
          <p className="health_text">
            With over 23 years of experience, Dr. Sunil Apsingi specializes in
            various orthopedic procedures to ensure the best care for you.
          </p>
          <div className="health_section layout_padding">
            <div className="row">
              <div className="col-sm-7">
                <div className="image_main">
                  <div className="main">
                    <img
                      src="images/image-(3).png"
                      alt="Knee Replacement Surgery"
                      className="image"
                      style={{
                        width: "100%"
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="image_main_1">
                  <div className="main">
                    <img
                      src="images/image-(4).png"
                      alt="Shoulder Replacement Surgery"
                      className="image"
                      style={{
                        width: "100%"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="getquote_bt_1">
              <Link to="../profile">
                Read More{" "}
                <span>
                  <img src="images/right-arrow.png" alt="Right Arrow Icon" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Health;

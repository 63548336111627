import React, { useState, useEffect } from "react";
import Gallery from "./Gallery";
import NavBar from "./NavBar";
import Footer from "./Footer";
import BoltLoader from './loaders/boltloader';

function GallerySection() {
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/gallery/`);
        if (!response.ok) {
          throw new Error("Failed to fetch media items");
        }
        const data = await response.json();
        setMediaItems(data);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchMediaItems();
  }, []);

  if(mediaItems.length===0){
    return <>
    <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <BoltLoader
        className={"loaderbolt"}
        boltColor={"#fa6616"}
        backgroundBlurColor={"#E0E7FF"}
      />
      </div>
  </>;
  }

  return (
    <>
      <NavBar />
      <div className="health_section layout_padding" id="aboutus">
        <div className="container jumbotron">
          <div className="container-fluid">
            <Gallery mediaItems={mediaItems} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GallerySection;

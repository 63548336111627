import React, { useState, useEffect } from "react";
import reviewsData from "../data/reviews.json"; // Import the JSON data

const Reviews = () => {
  const [selectedReviews, setSelectedReviews] = useState([]);

  useEffect(() => {
    // Function to get random reviews with text longer than 100 characters
    const getFilteredReviews = () => {
      // Filter reviews to only include those with more than 100 characters in review_text
      const filteredReviews = reviewsData.filter(
        (review) => review.review_text && review.review_text.length > 100
      );

      // Shuffle and pick the first 5 from the filtered reviews
      const shuffledReviews = filteredReviews.sort(() => 0.5 - Math.random());
      return shuffledReviews.slice(0, 5);
    };

    setSelectedReviews(getFilteredReviews());
  }, []);

  return (
    <section className="client_section layout_padding">
      <div className="container">
        <div className="heading_container">
          <h1 className="contact_taital">What People Say</h1>
        </div>
      </div>
      <div className="container px-0">
        <div
          id="customCarousel2"
          className="carousel carousel-fade"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {selectedReviews.map((review, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="box" style={{ backgroundColor: "aliceblue" }}>
                  <div className="client_info">
                    <div className="client_name">
                      <h5>{review.author_title}</h5>
                      <span className="rating">
                        {Array.from({ length: 5 }).map((_, starIndex) => (
                          <i
                            key={starIndex}
                            className={`fa ${
                              starIndex < review.review_rating
                                ? "fa-star"
                                : "fa-star-o"
                            }`}
                          />
                        ))}
                      </span>
                      <h6>{review.name}</h6>
                    </div>
                    <i className="fa fa-quote-left" aria-hidden="true" />
                  </div>
                  <p>{review.review_text}</p>

                  {/* Display owner reply if available */}
                  {review.owner_answer && (
                    <div className="owner_reply" style={{ marginTop: "10px" }}>
                      <strong>Our Response:</strong>
                      <p>{review.owner_answer}</p>
                    </div>
                  )}

                  {/* Button for Review Link */}
                  <div className="review_link" style={{ marginTop: "10px" }}>
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-success"
                    >
                      View Full Review
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="carousel_btn-box">
            <a
              className="carousel-control-prev"
              href="#customCarousel2"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-angle-left" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#customCarousel2"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-angle-right" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;

import React from "react";
import { Link } from "react-router-dom";

class Banner extends React.Component {
  render() {
    return (
      <div id="main_slider" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="banner_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="banner_taital">
                      Orthopedic Surgeon <br />
                      <span
                        style={{
                          color: "#151515"
                        }}
                      >
                        Dr. Sunil Apsingi
                      </span>
                    </h1>
                    <p className="banner_text">
                      Over 23 years of experience in orthopedic and joint
                      replacement surgery
                    </p>
                    <div className="btn_main">
                      <div className="more_bt">
                        <Link to="bookappointment">Book Consultation</Link>
                      </div>
                      {/* <div className="contact_bt">
                        <Link to="contact">Contact Now</Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="image_1">
                      <img style={{float: "right"}} src="images/2022-05-13.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="banner_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="banner_taital">Expert Orthopedic Care</h1>
                    <p className="banner_text">
                      Providing comprehensive orthopedic solutions for a
                      pain-free life
                    </p>
                    <div className="btn_main">
                      {/* <div className="more_bt">
                        <Link to="">Learn More</Link>
                      </div> */}
                      <div className="contact_bt">
                        <Link to="contact">Contact Now</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="image_1">
                      <img src="images/image-(1).png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="banner_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="banner_taital">
                      Advanced Joint Replacement
                    </h1>
                    <p className="banner_text">
                      Specializing in knee and shoulder replacement surgeries
                    </p>
                    <div className="btn_main">
                      {/* <div className="more_bt">
                        <Link to="bookappointment">Book Consultation</Link>
                      </div> */}
                      <div className="contact_bt">
                        <Link to="contact">Contact Now</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="image_1">
                      <img src="images/image-(2).png"  alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#main_slider"
          role="button"
          data-slide="prev"
        >
          <i
            className="fa fa-long-arrow-left"
            style={{
              fontSize: "24px",
              paddingTop: "4px"
            }}
          />
        </a>
        <a
          className="carousel-control-next"
          href="#main_slider"
          role="button"
          data-slide="next"
        >
          <i
            className="fa fa-long-arrow-right"
            style={{
              fontSize: "24px",
              paddingTop: "4px"
            }}
          />
        </a>
      </div>
    );
  }
}

export default Banner;

import React from "react";
import { Link } from "react-router-dom";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Home" // Set default active tab
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    // Check the URL pathname and set the active tab accordingly
    const pathname = window.location.pathname;
    const tabName = pathname.slice(1) || "Home"; // Remove leading slash
    this.setState({ activeTab: tabName });
  }

  handleTabClick(tabName) {
    this.setState({ activeTab: tabName });
  }

  render() {
    const { activeTab } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="logo">
          <Link to="/">
            <img src="../images/logo.png" alt="Logo" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className={`nav-item ${activeTab === "Home" ? "active" : ""}`}>
              <Link className="nav-link" to="../" onClick={() => this.handleTabClick("Home")}>
                Home
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "About" ? "active" : ""}`}>
              <Link className="nav-link" to="../aboutus" onClick={() => this.handleTabClick("About")}>
              About
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "Services" ? "active" : ""}`}>
              <Link className="nav-link" to="../services" onClick={() => this.handleTabClick("Services")}>
                Services
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "News" ? "active" : ""}`}>
              <Link className="nav-link" to="../blogs" onClick={() => this.handleTabClick("News")}>
              Blogs
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "Gallery" ? "active" : ""}`}>
              <Link className="nav-link" to="../gallery" onClick={() => this.handleTabClick("Gallery")}>
                Gallery
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "Contact" ? "active" : ""}`}>
              <Link className="nav-link" to="../contact" onClick={() => this.handleTabClick("Contact")}>
                Contact Us
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "UsefulLinks" ? "active" : ""}`}>
              <Link className="nav-link" to="/usefullinks" onClick={() => this.handleTabClick("UsefulLinks")}>
                Useful Links
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;

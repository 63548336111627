import React from "react";

class WhyUs extends React.Component {
  render() {
    return (
      <div className="news_section layout_padding">
        <div className="container">
          <h1 className="health_taital">Why Choose Dr. Sunil Apsingi?</h1>
          <p className="health_text">
            Dr. Sunil Apsingi offers comprehensive medical care with a focus on
            patient well-being and satisfaction.
          </p>
          <div className="news_section_2 layout_padding">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="box_main">
                  <div className="icon_1">
                    <img src="images/icon-2.png" alt="Experienced Specialist" />
                  </div>
                  <h4 className="daily_text">Experienced Specialist</h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="box_main active">
                  <div className="icon_1">
                    <img src="images/icon-3.png" alt="24/7 Availability" />
                  </div>
                  <h4 className="daily_text">24/7 Availability</h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="box_main">
                  <div className="icon_1">
                    <img src="images/icon-4.png" alt="Personalized Care" />
                  </div>
                  <h4 className="daily_text">Personalized Care</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyUs;

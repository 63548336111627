import React, { useState } from "react";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/`, formData);
      alert("Message sent successfully!");
      // Clear the form
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error sending the message!", error);
    }
  };

  return (
    <div className="contact_section">
      <div className="container">
        <br></br>
        <br></br>
        <h1 className="contact_taital center">Contact Us</h1>
        <div className="news_section_2">
          <div className="row">
            <div className="col-md-6">
              <div className="icon_main">
                <h4 className="diabetes_text">Phone: +91 8341555765</h4>
              </div>
              <div className="icon_main">
                <h4 className="diabetes_text">contact@arthroscopydoc.com</h4>
              </div>
              <div className="icon_main">
                <h4 className="diabetes_text">Location: Hyderabad</h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_box">
                <h1 className="book_text">Write for Us</h1>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className="Email_text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    className="Email_text"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <textarea
                    className="massage-bt"
                    placeholder="Message"
                    rows={5}
                    id="comment"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <br/>
                  <br/>
                  <div className="send_bt">
                    <button className="btn btn-success " type="submit">SEND</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

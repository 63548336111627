import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';
import BookLoader from './loaders/bookloader'
import htmlTruncate from 'html-truncate';

const Blogs = ({ showAll }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blogs from the Django API
    fetch(`${process.env.REACT_APP_API_URL}/api/articles/`) // Update with your actual API URL
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  // Determine how many blogs to show based on the showAll prop
  const displayBlogs = showAll ? blogs : blogs.slice(0, 3);

  if (showAll && blogs.length===0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <BookLoader
          background={"linear-gradient(135deg, #6066FA, #fa6616)"}
          desktopSize={"100px"}
          mobileSize={"80px"}
          textColor={"#fa6616"}
        />
      </div>
    );
  }

  return (
    <div className="contact_section layout_padding" id="news">
      <div id="my_slider" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <h1 className="client_taital">{showAll ? "Articles" : "Latest Articles"}</h1>
              <p className="client_text" style={{ margin: "revert" }}>
                Get regular Articles/Blog from Dr. Sunil Apsingi...
              </p>
              <div className="row text-left" style={{ justifyContent: "center" }}>
                {displayBlogs.map((blog) => (
                  <div className="col-md-4 d-flex align-items-stretch" key={blog.id}>
                    <div className="card border mb-4 d-flex flex-column">
                      <img
                        src={blog.poster || "https://via.placeholder.com/400x200"} // Use placeholder if no poster
                        alt={blog.title}
                        className="card-img-top w-100"
                      />
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title">{blog.title}</h5>
                        <div className="post-details mb-2">
                          <span>By: {blog.author} | {blog.specialist} <br/> {dateFormat(blog.datetime, "mmmm dS, yyyy")}</span>
                          {/* Add likes and comments here if available in your Django model */}
                        </div>
                        <p className="flex-grow-1" dangerouslySetInnerHTML={{ __html: htmlTruncate(blog.content, 100)}} ></p> {/* Show excerpt */}
                        <Link
                          to={`/blogs/${blog.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-auto"
                        >
                          Read More..
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {!showAll && (
                  <div className="getquote_bt_1">
                    <Link to="../blogs">
                      Read More{" "}
                      <span>
                        <img src="images/right-arrow.png" alt="Right Arrow Icon" />
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;

import React from "react";
import NavBar from "./NavBar";
import Banner from "./Banner";

class Header extends React.Component {
  render() {
    return (
      <div className="header_section">
        <NavBar></NavBar>
        <Banner></Banner>
      </div>
    );
  }
}

export default Header;

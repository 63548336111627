import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="footer_section layout_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer_logo">
                  <Link to="/">
                    <img src="../images/footer-logo.png" alt="Footer Logo" />
                  </Link>
                </div>
                <h1 className="adderss_text">Contact Us</h1>
                <div className="map_icon">
                  <img src="../images/map-icon.png" alt="Map Icon" />
                  <span className="paddlin_left_0">Hyderabad, Telangana</span>
                </div>
                <div className="map_icon">
                  <img src="../images/call-icon.png" alt="Call Icon" />
                  <span className="paddlin_left_0">+91 8341555765</span>
                </div>
                <div className="map_icon">
                  <img src="../images/mail-icon.png" alt="Mail Icon" />
                  <span className="paddlin_left_0"><small>contact@arthroscopydoc.com</small></span>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h1 className="adderss_text">Dr. Sunil Apsingi</h1>
                <div className="hiphop_text_1">
                Over 23 years of experience in orthopedic and joint replacement surgery
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h1 className="adderss_text">Useful Links</h1>
                <div className="Useful_text">
                  Gonna hit soon!
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h1 className="adderss_text">Newsletter</h1>
                <input
                  type="text"
                  className="Enter_text"
                  placeholder="Enter your Email"
                  name="Enter your Email"
                />
                <div className="subscribe_bt">
                  <a href="">Subscribe</a>
                </div>
                <div className="social_icon">
                  <ul>
                    <li>
                      <a href="https://www.youtube.com/channel/UCAJw_WKgtOHK20IDvGZYcvA">
                        <img src="../images/fb-icon.png" alt="Facebook Icon" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/sapsingi">
                        <img src="../images/twitter-icon.png" alt="Twitter Icon" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCAJw_WKgtOHK20IDvGZYcvA">
                        <img src="../images/linkedin-icon.png" alt="LinkedIn Icon" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCAJw_WKgtOHK20IDvGZYcvA">
                        <img src="../images/instagram-icon.png" alt="Instagram Icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright_section">
          <div className="container">
            <p className="copyright_text">
              2024 All Rights Reserved. Design by{" "}
              <a href="https://instagram.com/sayikumarr">Sayikumarr</a>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;

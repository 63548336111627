import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Knowledge() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="knowledge_section layout_padding">
        <div className="container">
          <div className="knowledge_main">
            <div className="left_main">
              <h1 className="knowledge_taital">
                Dr. Sunil Apsingi
              </h1>
              <p className="knowledge_text">
                With years of experience and expertise in orthopedic surgery,
                Dr. Sunil Apsingi is dedicated to providing top-notch medical
                care for his patients.
              </p>
            </div>
            <div className="right_main">
              <div className="play_icon" style={{"cursor": "pointer"}}>
                <a onClick={() => setModalShow(true)}>
                  <img src="images/play-icon.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Dr Sunil Apsingi Full Interview
        </Modal.Title>
        <Button onClick={() => window.open('https://www.youtube.com/channel/UCipAoi1WhtRmHFOk7Fk8PgQ', '_blank')} variant="secondary">More Videos!</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Header>
      <Modal.Body>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/lxIaBW3YKpo?si=ugdURTv8-KD36tve"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default Knowledge;

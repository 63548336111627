import React from "react";
import './Services.css';
import { Link } from 'react-router-dom';

class Services extends React.Component {
  static defaultProps = {
    showAll: false
  };

  render() {
    const { showAll } = this.props;

    return (
      <div className="contact_section layout_padding" id="services">
        <div className="container">
          <h1 className="contact_taital">Surgeries Offered</h1>
          <div className="news_section_2">
            <div className="row">
              <div className="col-md-6">
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-7.png" alt="Orthopedic Surgery" />
                  </div>
                  <h4 className="diabetes_text">Orthopedic Surgery</h4>
                </div>
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-5.png" alt="Sports Medicine" />
                  </div>
                  <h4 className="diabetes_text">Sports Medicine</h4>
                </div>
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-6.png" alt="Joint Replacement" />
                  </div>
                  <h4 className="diabetes_text">Joint Replacement</h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-7.png" alt="Trauma Surgery" />
                  </div>
                  <h4 className="diabetes_text">Trauma Surgery</h4>
                </div>
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-5.png" alt="Knee Injection" />
                  </div>
                  <h4 className="diabetes_text">Knee and Shoulder Injection</h4>
                </div>
                <div className="icon_main">
                  <div className="icon_7">
                    <img src="images/icon-6.png" alt="Cartilage Repair" />
                  </div>
                  <h4 className="diabetes_text">Surgery for Cartilage Repair</h4>
                </div>
              </div>
            </div>
            {showAll ? (
              <>
                <br />
                <hr />
                <h1 className="titleService">Knee Arthroscopic Surgery</h1>
                <div className="row">
                  <div className="col-md-6">
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="ACL Reconstruction" />
                      </div>
                      <h4 className="diabetes_text">ACL Reconstruction</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="Meniscal Repair" />
                      </div>
                      <h4 className="diabetes_text">Meniscal Repair</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-6.png" alt="PCL Reconstruction" />
                      </div>
                      <h4 className="diabetes_text">PCL Reconstruction</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="Multi-ligament Injuries" />
                      </div>
                      <h4 className="diabetes_text">Multi-ligament Injuries</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="Patella Dislocation" />
                      </div>
                      <h4 className="diabetes_text">Patella Dislocation Surgery</h4>
                    </div>
                  </div>
                </div>
                <br />
                <hr />
                <h1 className="titleService">Knee Surgery</h1>
                <div className="row">
                  <div className="col-md-6">
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="Knee Trauma Surgery" />
                      </div>
                      <h4 className="diabetes_text">Knee Trauma Surgery</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="Knee Replacement" />
                      </div>
                      <h4 className="diabetes_text">Knee Replacement Surgery</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-6.png" alt="Computer Navigated Knee" />
                      </div>
                      <h4 className="diabetes_text">Computer Navigated Knee Replacement</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="Robotic Knee" />
                      </div>
                      <h4 className="diabetes_text">Robotic Knee Replacement Surgery</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="HTO" />
                      </div>
                      <h4 className="diabetes_text">HTO</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-6.png" alt="Patellar Dislocation Surgery" />
                      </div>
                      <h4 className="diabetes_text">Surgery for Patellar Dislocation</h4>
                    </div>
                  </div>
                </div>
                <br />
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="titleService">Shoulder Arthroscopic Surgery</h1>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="Shoulder Dislocation" />
                      </div>
                      <h4 className="diabetes_text">Surgery for Shoulder Dislocation</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="Rotator Cuff Repair" />
                      </div>
                      <h4 className="diabetes_text">Rotator Cuff Repair</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-6.png" alt="Biceps Surgery" />
                      </div>
                      <h4 className="diabetes_text">Biceps Surgery</h4>
                    </div>
                    <hr />
                  </div>
                  <div className="col-md-6">
                    <h1 className="titleService">Shoulder Surgery</h1>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-7.png" alt="Shoulder Trauma" />
                      </div>
                      <h4 className="diabetes_text">Shoulder Trauma Surgery</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-5.png" alt="Total Shoulder Replacement" />
                      </div>
                      <h4 className="diabetes_text">Total Shoulder Replacement</h4>
                    </div>
                    <div className="icon_main">
                      <div className="icon_7">
                        <img src="images/icon-6.png" alt="Reverse Shoulder" />
                      </div>
                      <h4 className="diabetes_text">Reverse Shoulder Replacement</h4>
                    </div>
                    <hr />
                  </div>
                </div>
              </>
            ) : (
              <div className="getquote_bt_1">
                <Link to="../services">
                  Read More{" "}
                  <span>
                    <img src="images/right-arrow.png" alt="Right Arrow Icon" />
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;

import React, { Component } from 'react';
import './UsefulLinks.css';
import BookLoader from './loaders/bookloader'

export default class UsefulLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/api/usefullinks/`)
      .then((response) => response.json())
      .then((data) => this.setState({ links: data }))
      .catch((error) => console.error('Error fetching data:', error));
  }

  render() {
    const { links } = this.state;

    return (
      <>
        {/* <link rel="stylesheet" href="css/bootstrap.min.css"/> */}
        <br></br>
        <div className="container-fluid jumbotron">
          <div className="row">
            <div className="col-md-12">
              {links.length > 0 ? (
                <div className="card mb-3">
                  <div className="card-header pr-0 pl-0">
                    <div className="row no-gutters align-items-center w-100">
                      <div className="col font-weight-bold pl-3">Title</div>
                      <div className="d-none d-md-block col-6 text-muted">
                        <div className="row no-gutters align-items-center">
                          <div className="col-6">Discrption</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {links.map((link, index) => (
                    <div key={index}>
                      <div className="card-body py-3">
                      <a href={link.link}>
                        <div className="row no-gutters align-items-center">
                          <div className="col">
                            <div  className="text-big font-weight-semibold" data-abc="true">{link.title}</div>
                          </div>
                          <div className="d-none d-md-block col-6">
                            <div className="row no-gutters align-items-center">
                              <div className="media col-6 align-items-center">
                                <img src={link.img} alt="" className="d-block ui-w-30 rounded-circle" />
                                <div className="media-body flex-truncate ml-2 d-block " data-abc="true">
                                 {link.discrption}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </a>
                      </div>
                      <hr className="m-0" />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh", // Full viewport height
                    }}
                  >
                    <BookLoader
                      background={"linear-gradient(135deg, #6066FA, #fa6616)"}
                      desktopSize={"100px"}
                      mobileSize={"80px"}
                      textColor={"#fa6616"}
                    />
                  </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

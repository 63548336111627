import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import axios from 'axios';

export default class BookAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: '',
      doctorName: '',
      departmentName: '',
      phoneNumber: '',
      symptoms: '',
      date: new Date(),  // Default to current date
      time: new Date(),  // Default to current time
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleDateChange = (date) => {
    this.setState({ date });
  }

  handleTimeChange = (time) => {
    this.setState(prevState => ({
      time: new Date(new Date().setHours(time.getHours(), time.getMinutes()))
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { patientName, doctorName, departmentName, phoneNumber, symptoms, date, time } = this.state;
    const appointmentDateTime = new Date(date.setHours(time.getHours(), time.getMinutes()));
    
    const data = {
      patient_name: patientName,
      contact_number: phoneNumber,
      appointment_date: appointmentDateTime.toISOString().split('T')[0], // YYYY-MM-DD
      appointment_time: appointmentDateTime.toISOString().split('T')[1].split('.')[0], // HH:MM:SS
      reason: symptoms,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/appointments/`, data);
      console.log('Appointment Created:', response.data);
      alert('Appointment Created');

      // Clear the form data
      this.setState({
        patientName: '',
        doctorName: '',
        departmentName: '',
        phoneNumber: '',
        symptoms: '',
        date: new Date(),  // Reset to current date
        time: new Date(),  // Reset to current time
      });
    } catch (error) {
      console.error('There was an error creating the appointment!', error);
    }
  }

  render() {
    const minDate = new Date();
    const maxDate = addDays(new Date(), 7);
    const minTime = new Date();
    minTime.setHours(10, 0, 0, 0); // 10 AM

    const maxTime = new Date();
    maxTime.setHours(18, 0, 0, 0); // 6 PM

    return (
      <section className="book_section layout_padding">
        <br /><br />
        <div className="container">
          <div className="row">
            <div className="col">
              <form onSubmit={this.handleSubmit} style={{ backgroundColor: "cornsilk" }}>
                <h4>
                  BOOK <span>APPOINTMENT</span>
                </h4>
                <div className="form-row">
                  <div className="form-group col-lg-4">
                    <label htmlFor="patientName">Patient Name</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      id="patientName" 
                      placeholder="Enter patient name" 
                      value={this.state.patientName}  // Bind to state
                      onChange={this.handleChange} 
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="doctorName">Doctor's Name</label>
                    <select 
                      className="form-control wide" 
                      id="doctorName" 
                      value={this.state.doctorName}  // Bind to state
                      onChange={this.handleChange}
                    >
                      {/* <option value="">Select Doctor</option> */}
                      <option value="Drsunil">Doctor Sunil Apsingi</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="departmentName">Department's Name</label>
                    <select 
                      className="form-control wide" 
                      id="departmentName" 
                      value={this.state.departmentName}  // Bind to state
                      onChange={this.handleChange}
                    >
                      {/* <option value="">Select Department</option> */}
                      <option value="DepartOrthopedic">Orthopedic</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-lg-4">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input 
                      type="tel" 
                      className="form-control" 
                      id="phoneNumber" 
                      placeholder="Enter phone number" 
                      value={this.state.phoneNumber}  // Bind to state
                      onChange={this.handleChange} 
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputDate">Choose Date </label>
                    <div id="inputDate">
                      <DatePicker
                        selected={this.state.date}
                        onChange={this.handleDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        placeholderText="Select a date"
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputTime">Choose Time</label>
                    <div id="inputTime">
                      <DatePicker
                        selected={this.state.time}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        minTime={minTime}
                        maxTime={maxTime}
                        dateFormat="h:mm aa"
                        className="form-control"
                        placeholderText="Select time"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="symptoms">Issue</label>
                  <textarea 
                    className="form-control" 
                    id="symptoms" 
                    placeholder="Enter Your Problem" 
                    value={this.state.symptoms}  // Bind to state
                    onChange={this.handleChange} 
                  />
                </div>
                <div className="btn-box">
                  <button type="submit" className="btn">Submit Now</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br />
      </section>
    )
  }
}

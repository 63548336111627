import React from 'react';
import './DoctorProfile.css';

const DoctorProfile = () => {
  return (
    <div className="doctor-profile">
      <div className="hero">
        <div className="hero-content">
          <h1>Dr. Sunil Apsingi</h1>
          <p className="qualification">MS (PGI, Chandigarh)</p>
          <p className="role">Chief Arthroscopy & Joint Replacement Surgery</p>
        </div>
      </div>

      <div className="content-container">
        <div className="grid">
          <div className="card main-info">
            <div className="card-content">
              <img
                alt="Dr. Sunil Apsingi"
                className="profile-image"
                src="images/2022-05-13.png"
              />
              <div className="info">
                <div className="badges">
                  <span className="badge">⏱ Experience: 22+ Years</span>
                  <span className="badge">⏱ Timings: 10 AM - 4 PM</span>
                </div>
                <div className="contact-info">
                  <p><span className="icon">📍</span> Medicover Out Patient Center, HUDA Techno Enclave, HITEC City, Hyderabad, Telangana 500081</p>
                  <p><span className="icon">📞</span> 040-68334455</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card languages">
            <div className="card-content">
              <h2><span className="icon">🌐</span> Languages</h2>
              <ul>
                {["English", "తెలుగు (Telugu)", "हिन्दी (Hindi)", "मराठी (Marathi)"].map((lang, index) => (
                  <li key={index}>{lang}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="card expertise">
            <div className="card-content">
              <h2><span className="icon">🩺</span> Expertise</h2>
              <div className="expertise-grid">
                {[
                  "Arthoscopy Knee", "Arthoscopy Shoulder", "Knee Replacement Surgery",
                  "Shoulder Replacement Surgery", "ACL Reconstruction", "Meniscal Repair",
                  "Rotator Cuff surgery", "Bankart Surgery", "Knee Ligament Reconstruction",
                  "High Tibial Osteotomy", "Revision Knee Replacement", "Revision ACL Reconstruction",
                  "Revision Shoulder Replacement", "Trauma Surgery", "Arthoscopy Surgery"
                ].map((item, index) => (
                  <div key={index} className="expertise-item">
                    <span className="icon">🏅</span>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="card publications">
            <div className="card-content">
              <h2><span className="icon">📄</span> Publications</h2>
              <ul>
                {[
                  "Dynamic nature of alignment and variations in normal knees. K Deep, KK Eachempati. Accepted for publication BJJ, 2014.",
                  "Review: femoral tunnel placement for PCL reconstruction in relation to the PCL fibre bundle attachments. Apsingi S, Bull AM, Deehan DJ, Amis AA. Knee Surg Sports Traumatol Arthrosc. 2009 Mar 14. [Epub ahead of print]",
                  "Sudden loss of visual acuity following intra-articular steroid injection in to the knee joint: a case report. Balakrishnan S, Apsingi S, Manjure SB. Cases J. 2008 Dec 30;1(1):428",
                  "The role of PCL reconstruction in knees with combined PCL and posterolateral corner deficiency. Apsingi S, Nguyen T, Bull AM, Unwin A, Deehan DJ, Amis AA. Knee Surg Sports Traumatol Arthrosc. 2008 Feb;16(2):104-11.",
                  "Control of laxity in knees with combined posterior cruciate ligament and posterolateral corner deficiency: comparison of single-bundle versus double-bundle posterior cruciate ligament reconstruction combined with modified Larson posterolateral corner reconstruction. Apsingi S, Nguyen T, Bull AM, Unwin A, Deehan DJ, Amis AA. Am J Sports Med 2008 36: 487-494."
                ].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;